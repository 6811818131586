<template>

    <div class="row">
        <!-- Left Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Select Patient: </span>
                </div>

                <div class="col-md-7">
                    <AsyncSelect
                        placeholder="Patient Name, ID, Mobile No"
                        v-model="patient"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalQuery"
                        :format-label="formatPatientLabel"
                    />
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient ID: </span>
                </div>

                <div class="col-md-7">
                    <span> <strong>{{ patientId }}</strong> </span>
                </div>
            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Patient Phone No: </span>
                </div>

                <div class="col-md-7">
                    <span> <strong> {{patientMobile && patientMobile.value}} </strong> </span>
                </div>
            </div>

            <div class="mt-4">
                <AsyncSelect
                  placeholder="Select Product"
                  v-model="productId"
                  :api-service="fetchProductList"
                  :reduce="name => name.id"
                  :format-label="option => option.text"
                  :additional-query="additionalProductQuery"
                  label="text"
                />
            </div>

        </div>

        <div class="col-md-2">
            <button
              type="button"
              class="btn btn-primary"
              @click="openPatientAddModal"
            >
                New Patient
            </button>
        </div>

        <!-- Right Side -->
        <div class="col-12 col-sm-5 col-md-5 col-lg-5">

            <div class="row h-40 mb-5">

                <div class="col-md-7">
                    <input
                        v-model="patientSerialNo"
                        name="serial"
                        type="number"
                        class="form-control"
                        placeholder="Patient Id"
                        @keyup="searchPatientById"
                    />
                </div>

            </div>

            <div class="row h-40">
                <div class="col-md-5 d-flex align-items-center">
                    <span> Invoice Date: </span>
                </div>

                <div class="col-md-7">
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
            </div>
        </div>

        <AddPatientModal
            v-if="$store.state.isModalOpenTwo"
            :doctors="doctors"
            @onCreateProfile="onCreateProfile"
        />

        <Loader v-if="isLoader" />

    </div>

</template>

<script setup>
import {computed, inject, onMounted, ref, watch, reactive} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { useStore } from 'vuex';
import handlePurchase from '@/services/modules/purchase'
import handleHospital from '@/services/modules/hospital'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleProjects from "@/services/modules/procurement/project";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleInventory from "@/services/modules/inventory";
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import useDate from "@/services/utils/day";
import Loader from "@/components/atom/LoaderComponent";


const props = defineProps({
  addNewItem: {
    type: Function
  },
  products: {
    type: Array,
    default: []
  },
  tableItems: {
    type: Array
  }
})

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const date = useDate();
const patient = ref(null)
const userInfo = ref(null);
const isLoader = ref(false);
const patientSerialNo = ref(null);
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const selectedData = ref({});
const formData = inject('formData');
const showError = inject('showError')
let loader =ref(false);
const productId = ref(null);
const additionalQuery = {
    type: "patient"
}
const additionalProductQuery = {
  product_type: "services"
}
const doctors = reactive([]);

const {fetchProductList} = handlePurchase()
const {getProduct} = handleInventory()
const { fetchPatientDetailsBySerial } = handleHospital()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles, fetchSingleContactProfile} = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres();
const { fetchIpdRegisterList } = handleHospitalBilling()
const { formatPatientLabel } = useAsyncDropdownHelper()

//computed

const patientMobile = computed(() => {
    if (selectedData.value.profile_items && selectedData.value.profile_items.length) {
        return selectedData.value.profile_items.find(item => item.field === 'mobile_no')
    }
    return null
})

const patientId = computed(() => {
    if (
        selectedData.value.company_roles &&
        selectedData.value.company_roles.length &&
        selectedData.value.company_roles[0].pivot
    ) {
        return selectedData.value.company_roles[0].pivot.serial_no
    }
    return null
})

const openPatientAddModal = () => {
    const query = `?company_id=${companyId}&type=doctor`
    fetchContactProfiles(query).then((res) => {
        if(res.status) doctors.push(...res.data)
    }).then(() => {
        $store.state.isModalOpenTwo = true
    })
}

const searchPatientById = async () => {
    
    if(!patientSerialNo.value) return;
    userInfo.value = null
    patient.value = null;
    isLoader.value = true;
    const query = `?company_id=${companyId}`;


    await fetchPatientDetailsBySerial(query, patientSerialNo.value)
        .then((res) => {
            
            if (!res.status) {
                return showError(res.message)
            }
            
            userInfo.value = res.data
            patient.value = {
                id: userInfo.value.id,
                name: userInfo.value.full_name
            }
        })
    
    isLoader.value = false;
}

const onCreateProfile = (profileInfo) => {
    patient.value = profileInfo;
    patient.value.name = profileInfo.full_name
}

onMounted(async () => {
    formData.value.date = date.currentDate();
})

watch(patient, () => {
    const patientQuery = companyQuery + '&type=patient';
    formData.value.contact_profile_id = patient.value.id;
    fetchSingleContactProfile(patient.value.id, patientQuery).then(res => {
        if (res.data) {
            selectedData.value = formData.value.contact = res.data;
        }
    })
})

watch(productId, async () => {
  const productResp = await getProduct({
    id: productId.value,
    company_id: companyId
  })
  if (!productResp.data) {
    showError("Something went wrong please try again");
    return;
  }
  let selected = productResp.data;
  let product = {
    name: selected.name
  }
  props.tableItems.push({
    id: selected.id,
    product_id: productId.value,
    name: selected.name,
    description: selected.description.unit.description,
    quantity: 1,
    rate: selected.description.sales_price,
    discount: 0,
    vat: selected.description.vat_rate,
    sub_total: 0,
    discount_amount: 0,
    discount_percent: 0,
    vat_amount: 0,
    total: 0,
    isEdit: false,
    product: product
  })
})

</script>

<style>
.select label{
    font-size: 14px;
}
.h-40{
    height: 40px;
    align-items: center;
}
</style>